import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import Style from './style';

const Carousel: FC<PropsWithChildren> = ({ children }) => {
  const eleRef = useRef<HTMLDivElement | null>(null);
  const [enableSlide, isEnableSlide] = useState<boolean>(false);
  const moveStep = useRef<number>(0);

  const onResize = useCallback(() => {
    if (typeof eleRef.current !== 'undefined' && eleRef.current !== null && eleRef.current) {
      if (eleRef.current.scrollWidth > eleRef.current.clientWidth) {
        isEnableSlide(true);
      } else {
        isEnableSlide(false);
      }
    }
  }, [eleRef]);

  const childWidth = useMemo<number>(() => {
    if (typeof eleRef.current !== 'undefined' && eleRef.current !== null && eleRef.current) {
      return eleRef.current.children[0]?.children[0]?.clientWidth || 200;
    }
    return 200;
  }, [eleRef]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', onResize);
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 500);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', onResize);
      }
    };
  }, [onResize]);

  return (
    <Style>
      {enableSlide && (
        <button
          type="button"
          className="carousel-pre"
          onClick={() => {
            if (
              typeof eleRef.current !== 'undefined' &&
              eleRef.current !== null &&
              eleRef.current
            ) {
              eleRef.current.scroll({
                left: Number(eleRef.current?.scrollLeft) - childWidth,
                behavior: 'smooth',
              });
            }
          }}
        />
      )}
      <div
        className="carousel-container"
        ref={eleRef}
        onMouseDown={(e) => {
          if (typeof eleRef.current !== 'undefined' && eleRef.current !== null && eleRef.current) {
            moveStep.current = eleRef.current.scrollLeft + e.clientX;
          }
        }}
        onTouchStart={(e) => {
          if (
            typeof eleRef.current !== 'undefined' &&
            eleRef.current !== null &&
            eleRef.current &&
            e.touches &&
            e.touches[0]
          ) {
            moveStep.current = eleRef.current.scrollLeft + e.touches[0].clientX;
          }
        }}
        onMouseMove={(e) => {
          if (moveStep.current !== 0) {
            if (
              typeof eleRef.current !== 'undefined' &&
              eleRef.current !== null &&
              eleRef.current
            ) {
              eleRef.current.scrollLeft = moveStep.current - e.clientX;
            }
          }
        }}
        onTouchMove={(e) => {
          if (moveStep.current !== 0) {
            if (
              typeof eleRef.current !== 'undefined' &&
              eleRef.current !== null &&
              eleRef.current &&
              e.touches &&
              e.touches[0]
            ) {
              eleRef.current.scrollLeft = moveStep.current - e.touches[0].clientX;
            }
          }
        }}
        onMouseUp={(e) => {
          e.stopPropagation();
          moveStep.current = 0;
          return true;
        }}
        onTouchEnd={(e) => {
          e.stopPropagation();
          moveStep.current = 0;
          return true;
        }}
      >
        {children}
      </div>
      {enableSlide && (
        <button
          type="button"
          className="carousel-next"
          onClick={() => {
            if (
              typeof eleRef.current !== 'undefined' &&
              eleRef.current !== null &&
              eleRef.current
            ) {
              eleRef.current.scroll({
                left: Number(eleRef.current?.scrollLeft) + childWidth,
                behavior: 'smooth',
              });
            }
          }}
        />
      )}
    </Style>
  );
};

export default React.memo(Carousel);
