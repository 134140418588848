import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';
import React, { FC } from 'react';
import ReactModal, { Styles } from 'react-modal';

import ProductStyle from './style';

import { CartItem } from '@api/cartService';
import Carousel from '@components/common/Carousel';
import { modalStyle } from '@components/modal/style';
import { ModalCloseButtonStyle, ModalContentStyle } from '@components/mypage/NoticeModal/style';
import useCurrency from '@hooks/useCurrency';

// TODO api integrate
const messageItems = [
  {
    id: '1',
    image: 'https://irohashop.itembox.design/item/images/items/ggmb_6.jpg',
    name: 'あり',
    price: 110,
    shop: '',
  },
];

// TODO api integrate
const wrappingItems = [
  {
    id: '2',
    image: 'https://makeshop-multi-images.akamaized.net/GB17/itemimages/000000006808_tISRw4I.jpg',
    name: 'あり  (包装紙)',
    price: 110,
    shop: '',
  },
  {
    id: '3',
    image:
      'https://img07.shop-pro.jp/PA01456/358/etc/%CA%A9%BB%F6%CA%F1%C1%F5%BB%E61.JPG?cmsp_timestamp=20210225161818',
    name: 'あり  (包装紙＋熨斗)',
    price: 110,
    shop: '',
  },
];

// TODO api integrate
const otherItems = [
  {
    id: '4',
    image:
      'https://www.antenor.jp/shopping/html/user_data/shared/img/calendar/img_kouhaku_noshinashi.jpg',
    name: '表書きフリー (結び切り)',
    price: 0,
    shop: '',
  },
  {
    id: '5',
    image: 'https://www.illust-box.jp/db_img/sozai/00004/45037/watermark.jpg',
    name: '表書きフリー （蝶結び）',
    price: 0,
    shop: '',
  },
];

type Props = {
  item: CartItem | undefined;
  onClose: () => void;
};

const optionModalStyle: Styles = {
  ...modalStyle({
    width: 0,
    height: 0,
  }),
  overlay: {
    zIndex: 1000,
    backgroundColor: 'rgba(0, 0, 0, 0.56)',
    transition: 'all .5s',
  },
  content: {
    background: '#FAFAFA',
    boxSizing: 'border-box',
    width: '80%',
    maxWidth: '760px',
    minWidth: ' 350px',
    height: 'fit-content',
    minHeight: '300px',
    maxHeight: '450px',
    overflow: 'auto',
    margin: 'auto',
    top: '100px',
    inset: '25% 10px',
    borderRadius: '5px',
  },
};

const OtherOptionsModal: FC<Props> = ({ item, onClose }) => {
  const intl = useIntl();
  const currency = useCurrency();

  return (
    <ReactModal
      isOpen={typeof item !== 'undefined'}
      style={optionModalStyle}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => onClose()}
    >
      <ModalCloseButtonStyle
        onClick={() => {
          onClose();
        }}
      />
      <ModalContentStyle style={{ padding: '0px' }}>
        <h3 className="large">
          <strong>
            <FormattedMessage id="e-gift.option.hint1" values={{ e: item?.name }} />
          </strong>
        </h3>
        <Carousel>
          <ProductStyle>
            {messageItems.map((product, index) => (
              <div className="product-card" key={`product_${product.id}_${index}`}>
                <h5>{index === 0 ? 'メッセージカード' : ''}</h5>
                <img className="product-img" src={product.image} alt="Product" />
                <div className="product-body">
                  {product.name.split(' ').map((str, index) => (
                    <div key={`${str}_${index}`}>{str}</div>
                  ))}
                  <div className="product-price">{intl.formatNumber(product.price, currency)}</div>
                </div>
              </div>
            ))}
            {wrappingItems.map((product, index) => (
              <div className="product-card" key={`product_${product.id}_${index}`}>
                <h5>{index === 0 ? 'ラッピング' : ''}</h5>
                <img className="product-img" src={product.image} alt="Product" />
                <div className="product-body">
                  {product.name.split(' ').map((str, index) => (
                    <div key={`${str}_${index}`}>{str}</div>
                  ))}
                  <div className="product-price">{intl.formatNumber(product.price, currency)}</div>
                </div>
              </div>
            ))}
            {otherItems.map((product, index) => (
              <div className="product-card" key={`product_${product.id}_${index}`}>
                <h5>{index === 0 ? '熨斗上（表書く）' : ''}</h5>
                <img className="product-img" src={product.image} alt="Product" />
                <div className="product-body">
                  {product.name.split(' ').map((str, index) => (
                    <div key={`${str}_${index}`}>{str}</div>
                  ))}
                  <div className="product-price">{intl.formatNumber(product.price, currency)}</div>
                </div>
              </div>
            ))}
          </ProductStyle>
        </Carousel>
      </ModalContentStyle>
    </ReactModal>
  );
};

export default React.memo(OtherOptionsModal, (pre, next) => pre.item === next.item);
