import styled from 'styled-components';

export default styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: auto;
  background-color: white;
  border-radius: 10px;
  box-sizing: border-box;
  border: 1px solid #fdf6e8;
  box-shadow: 5px 5px 8px var(--color-bg-gray);
  margin-bottom: 1rem;
  box-sizing: border-box;

  .card-header {
    box-sizing: border-box;
    padding: 0.75rem 1rem;
    color: black;
    background-color: #fdf6e8;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    text-align: left;
  }

  .card-body {
    flex: 1 1 auto;
    padding: 0.75rem 1rem;
    color: black;
    box-sizing: border-box;
    border-radius: 10px;
    min-height: 200px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;

    & h5 {
      font-size: 15px;
      margin-bottom: 0.75rem;
    }

    & p {
      margin-bottom: 0.5rem;
    }
  }

  .card-header ~ .card-body {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
`;
