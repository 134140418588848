import styled from 'styled-components';

export default styled.div`
  margin: 10px -10px;
  box-sizing: border-box;
  display: inline-flex !important;
  flex-wrap: nowrap !important;

  .product-card {
    display: inline-block;
    box-sizing: border-box;
    width: 150px;
    height: 250px;
    margin: 5px 10px;

    h5 {
      display: block;
      width: 100%;
      margin: 0px 5px;
      height: 25px;
      box-sizing: border-box;
      font-family: 'Hiragino Kaku Gothic ProN';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #3b414a;
    }

    .product-img {
      display: block;
      width: 150px;
      height: 150px;
      margin: 5px auto;
      box-sizing: border-box;
      object-fit: contain;
      border-radius: 10px;
    }

    .product-body {
      font-family: 'Inter', 'Hiragino Kaku Gothic ProN';
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #3b414a;

      .product-price {
        font-weight: 600;
      }
    }
  }
`;
