import styled from 'styled-components';

export const VoucherStyle = styled.div`
  display: block;
  box-sizing: border-box;
  border: 1px solid var(--color-bg-gray);
  padding: 1.2rem 0.5rem;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 1rem;

  .voucher-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0.75rem;
    text-align: center;
  }

  .voucher-table {
    text-align: left;
    margin: auto;

    th,
    td {
      box-sizing: border-box;
      padding: 5px;
    }

    th {
      font-weight: 500;
      white-space: nowrap;
    }
  }
`;

export const HintStyle = styled.p`
  grid-column: 1 / -1;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #666666;
  text-align: left;
`;
