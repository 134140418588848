import { navigate } from 'gatsby';
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';
import React, { FC, useState } from 'react';

import { useAppSelector } from '../../../redux/hooks';

import { CartItem } from '@api/cartService';
import { GiftIcon } from '@assets/image/png';
import { ReactComponent as CartIcon } from '@assets/image/svg/cart.svg';
import Button from '@components/common/Button';
import { Layout } from '@components/layout';
import { Block } from '@components/layout/block';
import CardStyle from '@components/layout/common/card';
import { Flex } from '@components/layout/flexbox';
import OtherOptionsModal from '@features/e-cart/OtherOptionsModal';
import { VoucherStyle } from '@features/e-cart/VoucherSettingComponent/style';
import { Style } from '@features/e-cart/style';

// import useCart from '@hooks/useCart';
import useCurrency from '@hooks/useCurrency';

const EGiftAddressConfirmPage: FC = () => {
  // const { eGiftCart } = useCart();
  const current = useCurrency();
  const intl = useIntl();
  const { purchaser, voucher, publish, delivery } = useAppSelector(
    (state) => state.eGiftCardReducer
  );
  const [selectItem, setSelectItem] = useState<CartItem | undefined>(undefined);

  return (
    <Layout>
      <Style.Wrap style={{ padding: '0px', width: '100%' }}>
        <Style.Container className="bg-white left" style={{ padding: '10px 5px' }}>
          <Style.GiftHeader>
            <div className="card-icon">
              <CartIcon width="20px" height="20px" stroke="orange" />
            </div>
            <FormattedMessage id="delivery.info" />
            <div className="sub-title">
              <img src={GiftIcon as string} alt="GiftIcon" />
              <FormattedMessage id="breadcrumbs.e-gift" />
            </div>
          </Style.GiftHeader>
          <Style.GiftAddressContainer style={{ padding: '1rem 0rem' }}>
            <div className="e-gift-address-info col">
              <CardStyle style={{ minWidth: '350px', maxWidth: '95%', marginBottom: '1rem' }}>
                <div className="card-header">
                  <FormattedMessage id="origin" />
                  ：Tasty
                </div>
                <div className="card-body">
                  {/* <ListStyle>
                    {(eGiftCart.items || []).map((item, index) => (
                      <div className="list-item with-img with-btn" key={`${item.name}_${index}`}>
                        <img src={item.image} alt={item.name} />
                        <h5>{intl.formatNumber(item.price, current)}</h5>
                        <div className="font-weight-500">{item.name}</div>
                        <p>{item.name}</p>
                        <Button
                          width="250px"
                          borderRadius="5px"
                          className="secondary"
                          onClick={() => {
                            setSelectItem(item);
                          }}
                        >
                          <FormattedMessage id="e-gift.wrapping.options" />
                        </Button>
                      </div>
                    ))}
                  </ListStyle> */}
                </div>
              </CardStyle>
              <CardStyle style={{ minWidth: '350px', maxWidth: '95%', marginBottom: '1rem' }}>
                <div className="card-header">
                  <FormattedMessage id="e-gift.purchaser" />
                </div>
                <div className="card-body">
                  {purchaser && (
                    <Flex jc="flex-start" alignItems="flex-start" direction="column">
                      <Block textAlign="left">
                        <h5 className="mb-2">
                          <FormattedMessage id="e-gift.purchaser" />
                        </h5>
                        <p className="mb-0">
                          {purchaser.first_name} {purchaser.last_name} <FormattedMessage id="sir" />
                        </p>
                        <p className="mb-0">{purchaser.postcode}</p>
                        <p className="mb-0">
                          {purchaser.prefecture} {purchaser.address} {purchaser.building}{' '}
                        </p>
                        <p className="mb-0">{purchaser.tel}</p>
                      </Block>
                      <Block textAlign="left">
                        <h5 className="mb-2">
                          <FormattedMessage id="mail.address" />
                        </h5>
                        <p className="mb-0">{purchaser.email}</p>
                      </Block>
                    </Flex>
                  )}
                  <Flex jc="flex-start" alignItems="flex-start" direction="column">
                    <Block textAlign="left">
                      <h5 className="mb-2">
                        <FormattedMessage id="voucher.setting" />
                      </h5>
                      <p className="mb-0">
                        {intl.formatMessage({ id: voucher === 'custom' ? 'custom' : 'anonymous' })}
                      </p>
                    </Block>
                    <VoucherStyle>
                      <div className="voucher-title">
                        <FormattedMessage id="voucher" />
                      </div>
                      <table className="voucher-table">
                        <tbody>
                          <tr>
                            <th>
                              <FormattedMessage id="delivery.home" />:
                            </th>
                            <td>{voucher === 'custom' ? 'あなたの住所' : 'Tasty'}</td>
                          </tr>
                          <tr>
                            <th>
                              <FormattedMessage id="delivery.name" />:
                            </th>
                            <td>{voucher === 'custom' ? 'あなたのお名前' : 'Tasty事務局'}</td>
                          </tr>
                          <tr>
                            <th>
                              <FormattedMessage id="delivery.full.name" />:
                            </th>
                            <td>{voucher === 'custom' ? 'あなたの住所' : 'ニックネーム'}</td>
                          </tr>
                        </tbody>
                      </table>
                    </VoucherStyle>
                  </Flex>
                  <Flex jc="flex-start" alignItems="flex-start" direction="column">
                    <Block textAlign="left">
                      <p className="mb-0">
                        <small>
                          <FormattedMessage id="e-gift.hint11" />
                        </small>
                      </p>
                      <p className="mb-0">
                        <small>
                          <FormattedMessage id="e-gift.hint12" />
                        </small>
                      </p>
                      <p className="mb-0">
                        <small>
                          <FormattedMessage id="e-gift.hint13" />
                        </small>
                      </p>
                    </Block>
                  </Flex>
                  <div className="text-center">
                    <Button
                      width="200px"
                      className="secondary mb-4"
                      onClick={() => {
                        navigate('/e-gift/address-info');
                      }}
                    >
                      <FormattedMessage id="edit" />
                    </Button>
                  </div>
                </div>
              </CardStyle>
              <CardStyle style={{ minWidth: '350px', maxWidth: '95%', marginBottom: '1rem' }}>
                <div className="card-header">
                  <FormattedMessage id="breadcrumbs.address" />
                </div>
                <div className="card-body">
                  <Flex jc="flex-start" alignItems="flex-start" direction="column">
                    <Block textAlign="left">
                      <h5 className="mb-2">
                        <FormattedMessage id="product.public.setting" />
                      </h5>
                      <p className="mb-0">
                        <FormattedMessage
                          id={publish ? 'product.public.enabled' : 'product.public.disabled'}
                        />
                      </p>
                    </Block>
                    <Block textAlign="left">
                      <h5 className="mb-2">
                        <FormattedMessage id="breadcrumbs.address" />
                      </h5>
                      <p className="mb-0">
                        <FormattedMessage
                          id={delivery === 'enabled' ? 'delivery.option1' : 'delivery.option2'}
                        />
                      </p>
                    </Block>
                  </Flex>
                  <div className="text-center">
                    <Button
                      width="200px"
                      className="secondary mb-4"
                      onClick={() => {
                        navigate('/e-gift/address-info');
                      }}
                    >
                      <FormattedMessage id="edit" />
                    </Button>
                  </div>
                </div>
              </CardStyle>
            </div>
            {/* <div className="e-gift-price-summary col">
              <PriceSummaryComponent eGiftCart={eGiftCart} type="confirm" />
            </div> */}
          </Style.GiftAddressContainer>
        </Style.Container>
        <OtherOptionsModal
          item={selectItem}
          onClose={() => {
            setSelectItem(undefined);
          }}
        />
      </Style.Wrap>
    </Layout>
  );
};

export default React.memo(EGiftAddressConfirmPage);
