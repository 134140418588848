import styled from 'styled-components';

import { breakpoint } from '@constants/theme';

export default styled.div`
  display: block;
  position: relative;


  .carousel-container {
    overflow: hidden;
    display: block;
    width: 100%;

    > * {
      display:inline-flex;
      flex-wrap: nowrap;
      width: 100%;
    }

    @media (max-width: ${breakpoint.md}px) {
      > * {
        justify-items: center;
        display: inline-grid;
        grid-template-rows: repeat(2, auto);
        grid-auto-flow: column;
        grid-auto-columns: min-content;  
        
        > * {
          min-width: 200px;
        }
      }
    }
  }
}

.carousel-pre, .carousel-next {
  position: absolute;
  top: 35%;
  background: rgba(0, 0, 0, .8);
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: opacity .3s;
  opacity: .3;
  z-index: 900;
  
  &:hover {
    opacity: .8;
  }

  &:before {
    content: '';
    width: 30%;
    height: 30%;
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
  }
}

.carousel-pre {
  left: 0px;

  &:before {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    margin-right: -15%;
  }
}

.carousel-next {
  right: 0px;

  &:before {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    margin-left: -15%;
  }

}
`;
